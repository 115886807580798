<template>
  <div class="divider-container">
    <div class="divider-line"></div>
    <div class="center-label">
      <p>{{ label }}</p>
    </div>
    <div class="divider-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Closed',
    },
  },
};
</script>

<style scoped>
.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  position: relative;
  padding-bottom: 15px;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #f44336;
}

.center-label {
  padding: 5px 10px;
  background-color: #f44336;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  transform: translateY(15px);
}
</style>
